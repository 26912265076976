<template>
  <rs-card color="rs-error lighten-2">
    <rs-card-title class="font-weight-bold pb-0">
      <rs-icon color="rs-error" class="mr-2">rsfont-alert-filled-yellow</rs-icon>
      Your Password Has Been Compromised
    </rs-card-title>
    <rs-card-text class="pl-5 pt-0">
      This password was exposed in an external breach. Update it now to protect your account.
      <router-link :to="{ name: routeNames.FORGOT_PASSWORD }">Reset password</router-link>
    </rs-card-text>
  </rs-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { routeNames } from '@/types';

export default defineComponent({
  name: 'InsecurePasswordCard',
  setup() {
    return {
      routeNames,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 6px;
}
</style>
